import ApiService from '../service/ApiService';

let moduleApi = {};
let moduleName = "tai_khoan";

moduleApi.create = (dataForm) => {
  let api = {
    module: moduleName,
    type: "create",
    value: dataForm,
    message: {
      success: "hidden",
      error: "hidden"
    },
  };
  return ApiService(api);
};

moduleApi.update = (dataForm) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    // message: {
    //   success: "hidden",
    //   error: "hidden"
    // },
    value: dataForm
  };
  return ApiService(api);
};

moduleApi.list = (dataFilter) => {
  let api = {
    module: moduleName,
    type: "list",
    value: dataFilter
  };
  return ApiService(api);
};


moduleApi.delete = (id) => {
  let api = {
    module: moduleName,
    type: "delete",
    value: { id: id }
  };
  return ApiService(api);
};


moduleApi.findById = (id) => {
  let api = {
    module: moduleName,
    type: "findById",
    value: { id: id }
  };
  return ApiService(api);
};

moduleApi.disable = (id) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    message: {
      success: "Khóa tài khoản thành công",
      error: "Khóa tài khoản không thành công"
    },
    value: {
      id: id,
      disabled: true
    }
  };
  return ApiService(api);
};

moduleApi.undisable = (id) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    message: {
      success: "Mở khóa tài khoản thành công",
      error: "Mở khóa tài khoản không thành công"
    },
    value: {
      id: id,
      disabled: false
    }
  };
  return ApiService(api);
};


export default moduleApi;
