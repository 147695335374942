import ValidService from "../../../service/ValidService";
let model = {};

// dữ liệu form
model.dataForm = {
  account: {
    account: null, //string, required, minLength: 1 // Tài khoản
    roles: ['User'],
    password: null, //string, optional // Mật khẩu
    repassword: null, //string, optional // Mật khẩu
    disabled: true
  }
};

// validation form
model.validForm = {
  account: {
    account: [ValidService.required, ValidService.checkAccount], //string, required, minLength: 1 // Tài khoản
    password: [ValidService.required, ValidService.checkLength(6)], //string, optional // Mật khẩu
    repassword: [ValidService.required, ValidService.checkLength(6)], //string, optional // Mật khẩu
  }
};

// dữ liệu mẫu các form khác
model.dataFormOther = {

};

// validation các form khác
model.validFormOther = {

};

model.tableRules = {
  data: [],
  lengthMenu: [10, 20, 50, 100, 500],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 10,
  offset: 0,
  sort: "",
  defaultSort: ["account", "descending"],
  filters: "",
  recordSelected: [],
  dataSearch: {
    value: {
      account: "",
      disabled: ""
    },
    operator: {
      account: ":regex_i:",
      disabled: "",
    }
  }
};

export default model;
