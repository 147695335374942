import LanguageService from './LanguageService';

let ValidService = {};
ValidService.required = {
  required: true,
  message: LanguageService.lang.valid_required,
  trigger: "blur"
};
ValidService.requiredChange = {
  required: true,
  message: LanguageService.lang.valid_required,
  trigger: "change"
};
ValidService.checkPattern = (pattern, message) => {
  let messageResult = message == null ? LanguageService.lang.valid_pattern : message;
  return {
    pattern: pattern,
    message: messageResult,
    trigger: "blur"
  }
}
ValidService.checkAz09 = {
  pattern: /^[0-9a-zA-Z]+$/g,
  message: LanguageService.lang.valid_az09,
  trigger: "blur"
};
ValidService.checkAccount = {
  pattern: /^[a-z0-9_.-]+$/g,
  message: LanguageService.lang.valid_account,
  trigger: "blur"
};
ValidService.checkAz09Dot = {
  pattern: /^[\d.\.]$/g,
  message: LanguageService.lang.valid_az09Dot,
  trigger: "blur"
};
ValidService.checkPhone = {
  pattern: /^(0|\+?84|0084)\d{9}$/,
  message: LanguageService.lang.valid_phone,
  trigger: "blur"
};
ValidService.checkNumber = {
  pattern: /^[0-9]+$/,
  message: LanguageService.lang.valid_number,
  trigger: "blur"
};
ValidService.checkNumberFloatNotNegative = {
  pattern: /^[\d.]+$/,
  message: LanguageService.lang.valid_floatNegative,
  trigger: "blur"
};
ValidService.checkNumberInteger = {
  pattern: /^[-]?[\d]+$/,
  message: LanguageService.lang.valid_integer,
  trigger: "blur"
};
ValidService.checkNumberFloat = {
  pattern: /^[-]?[\d.]+$/,
  message: LanguageService.lang.valid_float,
  trigger: "blur"
};
ValidService.checkEmail = {
  pattern: /^[a-zA-Z0-9.!$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g,
  message: LanguageService.lang.valid_email,
  trigger: "blur"
};
ValidService.checkSign = {
  pattern: /^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/g,
  message: LanguageService.lang.valid_sign,
  trigger: "blur"
};
ValidService.checkSpace = {
  pattern: /^[^\s]*$/g,
  message: LanguageService.lang.valid_space,
  trigger: "blur"
};
ValidService.checkScript = {
  pattern: /^[\u00BF-\u1FFF\u2C00-\uD7FF\w\_\s]*$/g,
  message: LanguageService.lang.valid_script,
  trigger: "blur"
};
ValidService.checkLink = {
  pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
  message: LanguageService.lang.valid_link,
  trigger: "blur"
};
ValidService.checkTextFirst = {
  pattern: /^[a-zA-Z].*/g,
  message: LanguageService.lang.valid_textFirst,
  trigger: "blur"
};
ValidService.checkDateTime = {
  pattern: /^([1-9]|([012][0-9])|(3[01]))[/]([0]{0,1}[1-9]|1[012])[/]\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$/g,
  message: LanguageService.lang.valid_dateTime,
  trigger: "blur"
};
ValidService.checkDate = {
  pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g,
  message: LanguageService.lang.valid_date,
  trigger: "blur"
};
ValidService.checkYear = {
  pattern: /^\d{4}$/g,
  message: LanguageService.lang.valid_year,
  trigger: "blur"
};
ValidService.checkMonth = {
  pattern: /^\d{2}[/]\d{4}$/g,
  message: LanguageService.lang.valid_month,
  trigger: "blur"
};

ValidService.checkLength = (start, end) => {
  var mess = "";
  var pattern = "";

  if (start && end) {

    mess = `${LanguageService.lang.valid_insert} ${start} ${LanguageService.lang.valid_to} ${end} ${LanguageService.lang.valid_character}`;
    pattern = `.{${start},${end}}$`;
  } else if (start) {
    mess = `${LanguageService.lang.valid_insertThan} ${start} ${LanguageService.lang.valid_character}`;
    pattern = `.{${start},}$`;
  } else if (end) {
    mess = `${LanguageService.lang.valid_insertLess} ${end} ${LanguageService.lang.valid_character}`;
    pattern = `.{,${end}}$`;
  }
  return {
    pattern: new RegExp(pattern),
    message: mess,
    trigger: "blur"
  }
};


export default ValidService;
